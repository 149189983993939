import React, { useEffect, useState } from 'react';
import { Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from "react-router-dom";
import { createForm, editForm, getForm, removeForm } from '../../../api/protocol';
import { createFormTemplate, getFormTemplates, removeFormTemplate } from '../../../api/protocolTemplates';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization.js';
import { DynamicFormCreator } from '../../../components/DynamicForm/DynamicFormCreator';
import { ModalData } from '../../../components/Modals/ModalData';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { CustomRadio } from '../../../components/CustomControls/CustomRadio';
import { SaveAsTemplate } from '../../../components/Protocols/Visit/SaveAsTemplate';

export const VisitForm = (props) => {
    const { match: { params: { protocolId, formId, visitId } } } = props;
    let history = useHistory();
    const { t } = useTranslation();
    const [isAuthorized] = useAuthProtocol(protocolId);
    const [isLoading, setIsLoading] = useState(false);
    const [form, setForm] = useState(null);
    const [modal, setModal] = useState(null);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);
            getForm(protocolId, visitId, formId)
                .then(response => {
                    setForm(response.data);
                    setIsLoading(false);
                }).catch(error => {
                    HandleApiError(error);
                    setIsLoading(false);
                });
        }
        if (formId)
            fetchData();
        else
            setForm({
                type: '',
                title: ''
            });
    }, [protocolId, visitId, formId]);

    const [formTemplates, setFormTemplates] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await getFormTemplates(protocolId);
                setFormTemplates(response.data);
                setIsLoading(false);
            }
            catch (error) {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [protocolId]);


    const handleSaveClick = async (form) => {
        if (!form) return;

        setIsLoading(true);
        if (!formId) {
            try {
                await createForm(protocolId, visitId, form);
                setIsLoading(false);
                Success("protocolNotifications.form_Created");
                history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }
        else {
            try {
                await editForm(protocolId, visitId, formId, form);
                setIsLoading(false);
                Success("protocolNotifications.form_Updated");
                history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }
    }

    const handleRemoveForm = (id) => {
        removeForm(protocolId, visitId, id)
            .then(() => {
                history.push(`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`);
            }).catch(error => {
                console.error(error);
                HandleApiError(error);
            });
    }

    //#region Templates
    const handleSaveAsTemplate = async (form) => {
        setModal(<ModalData
            title={t("protocols.visit.formTemplate.saveAsTemplate_Title")}
            isShowing={true}
            size="medium"
            className=""
            hide={() => setModal(null)}
        >
            <SaveAsTemplate
                isLoading={isLoading}
                onCancel={() => setModal(null)}
                onSaveAsTemplate={(isCrossProtocol) => confirmSaveAsTemplate(form, isCrossProtocol)}
            />
        </ModalData>)
    }

    const confirmSaveAsTemplate = async (form, isCrossProtocol) => {
        try {
            setIsLoading(true);
            const formTemplate = await createFormTemplate(protocolId, isCrossProtocol, form);
            Success("protocolNotifications.formTemplate_Created");
            setIsLoading(false);
            setFormTemplates([...formTemplates, formTemplate.data]);
            setModal(null)
        } catch (error) {
            console.log(error)
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleRemoveTemplate = async (templateId) => {
        try {
            await removeFormTemplate(protocolId, templateId);
            Success("protocolNotifications.formTemplate_Removed");
            setFormTemplates(formTemplates.filter((item) => item.id !== templateId));
            setIsLoading(false);
        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }
    //#endregion

    const isDisabled = !isAuthorized(["Visits.Add"]);

    return (
        <>
            {modal}
            <DynamicFormCreator
                initialForm={form}
                formTemplates={formTemplates}
                protocolId={protocolId}
                formId={formId}
                backUrl={`/admin/protocols/${protocolId}/visitsTemplate/${visitId}`}
                onRemove={handleRemoveForm}
                onSave={handleSaveClick}
                onSaveAsTemplate={handleSaveAsTemplate}
                onRemoveTemplate={handleRemoveTemplate}
                isDisabled={isDisabled}
                isLoading={isLoading}
            />
        </>
    );
}