import { useState } from "react";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getAuditUserRoleAPI, getRolesById, getUsersByIdAPI } from "../../../api/identity";

export const useAuditsUserRole = () => {
    const [audits, setAudits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (userId) => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            var response = await getAuditUserRoleAPI(userId);
            if (!response.data)
                return null;

            // Obtengo datos del usuario q realizó la acción
            var usersResponse = await getUsersByIdAPI(response.data.filter(x => x.actionBy).map(x => { return x.actionBy }))
            if (usersResponse.data) {
                response.data.forEach(item => {
                    item.actionByUser = usersResponse.data.find(x => x.userId == item.actionBy)
                });
            }

            // Obtengo datos del Rol
            var rolesResponse = await getRolesById(response.data.map(x => { return x.roleId }))
            if (rolesResponse.data) {
                response.data.forEach(item => {
                    let role = rolesResponse.data.find(x => x.id == item.roleId);
                    if (role) {
                        role.name = role.name.replace(`${role.tenantId}_`, "");
                        item.role = role;
                    }
                });
            }

            setAudits(response.data);
            setIsLoading(false);
        }
        catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    return [fetchData, audits, isLoading];
}