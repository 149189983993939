import { useState } from "react";
import { copyFormsAPI, getForms } from "../../../api/protocol";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";

export const useForms = () => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (protocolId, visitId) => {
        try {
            setIsLoading(true);
            const response = await getForms(protocolId, visitId);
            setData(response.data.sort((function (a, b) { return a.order - b.order; })));
            setIsLoading(false);
        }
        catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    return [fetchData, data, setData, isLoading];
}

export const useCopyForms = (protocolId, visitId) => {
    const [isLoading, setIsLoading] = useState(false);

    const copyForms = async (fromProtocolId, fromVisitId, formsToCopyIds) => {
        try {
            setIsLoading(true);
            const response = await copyFormsAPI(protocolId, visitId, fromProtocolId, fromVisitId, formsToCopyIds);
            setIsLoading(false);

            return response;

        } catch (error) {
            HandleApiError(error);
            setIsLoading(false);

            return null;
        }
    }

    return [copyForms, isLoading];
}