import PropTypes from 'prop-types';
import React, { useEffect, useState } from "react";
import { CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import Card from '../../../components/Cards/Card';
import { AuditTooltip } from '../../../components/CustomControls/AuditTooltip';
import { HandleApiError, HandleMessageError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { EvolutionForm, Evolutions } from '../../../components/Protocols/Tracking/Entry';
import { visitStatus } from '../../../variables/Enums';
import { useEvolutions } from './useEvolutions';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';

export const EvolutionsContainer = (props) => {
    const { data, audit, title, subtitle, isDisabled, visitTrackingStatus, protocolId, medicalRecordNumber, visitType } = props;
    const [isLoading, saveEvolution, updateEvolution] = useEvolutions(visitType);
    const [evolution, setEvolution] = useState(null);
    const [evolutions, setEvolutions] = useState(data);
    const [modal, setModal] = useState(null);

    useEffect(
        () => setEvolutions(data),
        [data]
    );

    const handleEditEvolution = (evolutionId) => {
        var evolutionEdit = evolutions.filter(x => (x.id === evolutionId));
        if (evolutionEdit.length > 0)
            setEvolution(evolutionEdit[0]);
        else
            HandleMessageError("protocolNotifications.visitTracking_evolutionNotFound");
    }

    const handleEvolutionEdited = async (evolutionId, text) => {
        if (visitTrackingStatus !== visitStatus.Started) {
            setModal(<CreateAuditModal
                onClose={() => setModal(null)}
                onSave={(reason, comments) => _updateEvolution(evolutionId, text, { reason: reason, comments: comments })}
            />)
        }
        else {
            _updateEvolution(evolutionId, text);
        }
    }

    const handleSaveEvolution = (data) => {
        if (visitTrackingStatus !== visitStatus.Started) {
            setModal(<CreateAuditModal
                onClose={() => setModal(null)}
                onSave={(reason, comments) => _saveEvolution(data, { reason: reason, comments: comments })}
            />)
        }
        else {
            _saveEvolution(data);
        }
    }

    function handleCancel() {
        setEvolution(null);
    }

    const _saveEvolution = async (evolutionText, audit) => {
        try {
            var savedEvolutionRes = await saveEvolution(props.protocolId, props.visitId, props.patientId, medicalRecordNumber, evolutionText, audit);
            if (savedEvolutionRes && savedEvolutionRes.data) {
                let savedEvolution = savedEvolutionRes.data;
                setEvolutions([...evolutions, savedEvolution]);
                setEvolution(null);
                Success("protocolNotifications.visitTracking_evolutionSaved");
            }
        }
        catch (error) {
            setEvolution({ text: evolutionText });  // Por Si no pudo guardar por algún error, populo de vuelta el editor con el contenido sin guardar. 
            HandleApiError(error);
            console.log(error);
        }
        finally {
            setModal(null);
        }
    }
    const _updateEvolution = async (evolutionId, text, audit) => {
        try {
            var updatedEvolution = await updateEvolution(props.protocolId, props.visitId, props.patientId, evolutionId, text, audit);
            if (updatedEvolution) {
                let evolutionsCopy = JSON.parse(JSON.stringify(evolutions));
                var index = evolutions.findIndex(x => x.id === evolutionId);
                evolutionsCopy.splice(index, 1, updatedEvolution.data); //Reemplazo. 
                setEvolutions(evolutionsCopy);
                setEvolution(null);
                Success("protocolNotifications.visitTracking_evolutionUpdated");
            }
        }
        catch (error) {
            console.error(error);
            HandleApiError(error);
        }
        finally {
            setModal(null);
        }
    }

    return (
        <Card
            title={title}
            subtitle={subtitle}
            content={
                <>
                    {audit && <AuditTooltip id="visitText" text={audit} />}
                    {modal}
                    <WithAuth protocolId={protocolId} requiredPermission={["InternalNotes.List"]}>
                        <EvolutionForm
                            evolution={evolution}
                            visitStatus={visitTrackingStatus}
                            onSave={handleSaveEvolution}
                            onUpdate={handleEvolutionEdited}
                            onCancel={handleCancel}
                            isDisabled={isDisabled}
                            isLoading={isLoading}
                        />
                    </WithAuth>

                    <Evolutions
                        protocolId={protocolId}
                        data={evolutions}
                        isLoading={false}
                        onEdit={handleEditEvolution}
                    />
                </>
            }
        />
    );
}

EvolutionsContainer.defaultProps = {
    //title: "Evolución"
}
EvolutionsContainer.propTypes = {
    title: PropTypes.object,
    subtitle: PropTypes.object,
    placeholder: PropTypes.string,
    isDisabled: PropTypes.bool,
    values: PropTypes.object.isRequired,
    setFieldValue: PropTypes.func.isRequired,
    setFieldTouched: PropTypes.func.isRequired,
};