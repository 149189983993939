import React, { useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";

import { removeStudyRole } from "../../../api/security";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { StudyRoles } from "../../../components/Authorization/StudyRoles";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import Card from "../../../components/Cards/Card";
import { Add, Audit } from "../../../components/Icons/Icons";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import { AuditRolePermissionsContainer } from "../../Protocols/Audit/AuditRolePermissions";
import { useStudyRoles } from "./useStudyRoles";

export const StudyRolesContainer = (props) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);
    const [roles, isLoading, reloadRoles] = useStudyRoles();

    const handleEditRole = (roleId) => {
        history.push(`/admin/settings/studyRoles/${roleId}`);
    }

    const handleRemoveRole = (roleId) => {
        if (roleId) {
            removeStudyRole(roleId)
                .then(res => {
                    reloadRoles(true);
                    Success("security.studyRole.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    //#region Audit
    const handleAuditUserRole = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditRolePermissionsContainer />
            </AuditModal>
        )
    }
    //#endregion

    return (
        <>
            {modal}
            <Card
                tabs
                header={
                    <Row>
                        <Col md={2}>
                            <WithAuth requiredPermission={["Protocols.Create"]}>
                                <NavLink to={`/admin/settings/studyRoles/create`} className="btn btn-primary" activeClassName="active">
                                    <Add /> {t("security.studyRoles.add")}
                                </NavLink>
                            </WithAuth>
                        </Col>
                        <Col md={6} mdOffset={2}>
                        </Col>
                        <Col md={2}>
                            <button className="btn btn-default btn-right" onClick={handleAuditUserRole} style={{ marginRight: '10px' }}>
                                <Audit /> {t("auditTrail.auditPermissions")}
                            </button>
                        </Col>
                    </Row>
                }
                content={
                    <StudyRoles
                        roles={roles}
                        onEdit={handleEditRole}
                        onRemove={handleRemoveRole}
                        isLoading={isLoading}
                    />
                }
            />
        </>
    );
}