import React from 'react';
import { useTranslation } from 'react-i18next';
//Components
import { Col, Row } from 'react-bootstrap';
import { AuditRolePermissions } from '../../../components/AuditTrail/AuditRolePermissions';
import { CustomSelect } from '../../../components/CustomControls/CustomSelect';
import { useRoleMasterData } from '../Authorization/useRoles';
import { useAuditRolePermissions } from './hooks';

export const AuditRolePermissionsContainer = () => {
    const { t } = useTranslation();
    const [, roles] = useRoleMasterData();
    const [getAudits, audits, isLoading] = useAuditRolePermissions();

    const handleSearch = (_name, value) => {
        if (value)
            getAudits(value.id);
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <CustomSelect
                        name="role"
                        placeholder={t("commons.select")}
                        onChange={handleSearch}
                        options={roles?.roles}
                        getOptionLabel={x => x.name}
                    />
                </Col>
            </Row>
            <Row>
                <AuditRolePermissions
                    data={audits}
                    totalSize={audits?.length}
                    isLoading={isLoading}
                />
            </Row>
        </>
    );
}