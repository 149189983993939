import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
//Components
import { Col, Row } from 'react-bootstrap';
import { AuditProtocolUserRole } from '../../../components/AuditTrail/AuditUserRole';
import { PractitionerPicker } from '../../../components/CustomControls/PractitionerPicker';
import { useAuditsUserRole } from './hooks';

export const AuditUserRoleContainer = () => {
    const { t } = useTranslation();
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "FullName",
            sortOrder: "asc"
        },
        filters: {}
    });

    const [getAudits, audits, isLoading] = useAuditsUserRole();

    const handleSearch = (_name, value) => {
        if (value)
            getAudits(value.userId);
    }

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = {};
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <PractitionerPicker
                        multiple={false}
                        onlyDoctor={false}
                        includeDisabled={true}
                        placeholder={t("commons.selectPractitioner")}
                        onChange={handleSearch}
                    />
                </Col>
            </Row>
            <Row>
                <AuditProtocolUserRole
                    data={audits}
                    onTableChange={handleTableChange}
                    query={query}
                    totalSize={audits?.length}
                    isLoading={isLoading}
                />
            </Row>
        </>
    );
}