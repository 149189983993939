import React, { useState } from 'react';

import { ControlLabel, Col, Grid, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useTranslation } from 'react-i18next';
import { ProtocolPicker } from '../../CustomControls/ProtocolPicker';
import { Copy } from '../../Icons/Icons';
import { OffsetTypes, VisitStages } from './Enums';

export const CopyVisits = ({ visits, isLoading, onSelectedProtocol, onCopyVisits, onCancel }) => {
    const { t } = useTranslation();
    const [selectedProtocol, setSelectedProtocol] = useState(null);
    const [selectedVisits, setSelectedVisits] = useState([]);

    const handleProtocolSelected = (_name, selected) => {
        if (onSelectedProtocol && selected && selected.length > 0) {
            setSelectedProtocol(selected[0].id);
            onSelectedProtocol(selected[0].id);
        }
        else {
            setSelectedProtocol(null);
            onSelectedProtocol(null);
        }
    }

    const handleCopyVisits = () => {
        if (selectedProtocol && selectedVisits.length > 0)
            onCopyVisits(selectedProtocol, selectedVisits);
    }

    const columns = [
        { dataField: 'title', text: t("protocols.visit.visitName") },
        {
            dataField: 'offset', text: t("protocols.visit.visitOffset"),
            formatter: function (cell, row) {
                return `${cell} ${OffsetTypes().find(x => x.id === row.offsetType)?.title}`;
            }
        },
        {
            dataField: 'stage', text: t("protocols.visit.visitStage"),
            formatter: function (cell) {
                return VisitStages().find(x => x.id === cell)?.title;
            }
        },
    ];

    const selectRow = {
        mode: 'checkbox',
        onSelect: (row, isSelect) => {
            if (isSelect)
                setSelectedVisits(selectedVisits.concat(row.id));
            else
                setSelectedVisits(selectedVisits.filter((id) => id !== row.id));
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                setSelectedVisits(rows.map(r => r.id));
            }
            else {
                setSelectedVisits([]);
            }
        },
    };

    return (<>
        <Grid fluid>
            <Row>
                <Col sm={12}>
                    <ControlLabel>{t("protocols.visit.copyVisits_selectProtocol")}</ControlLabel>
                    <ProtocolPicker
                        id="copy-visits"
                        name="protocols"
                        multiple={false}
                        placeholder={t("filters.protocol")}
                        onChange={handleProtocolSelected} />
                </Col>

            </Row>
        </Grid>
        {
            visits && visits.length > 0 &&
            <Grid fluid style={{ marginTop: '10px' }}>
                <Row>
                    <Col sm={12}>
                        <ControlLabel>{t("protocols.visit.copyVisits_protocolVisits")}</ControlLabel>
                        <BootstrapTable
                            classes="table-spaced table-spaced-smallest table-spaced-small"
                            wrapperClasses="table-responsive"
                            bordered={false}
                            hover
                            remote
                            keyField='id'
                            data={visits}
                            columns={columns}
                            noDataIndication={() => <>{t("commons.noData")}</>}
                            loading={isLoading}
                            overlay={overlayFactory({ spinner: true })}
                            selectRow={selectRow}
                        />
                    </Col>
                </Row>
            </Grid>
        }
        <Grid fluid style={{ marginTop: '10px' }}>
            <Row>
                <Col sm={12}>
                    <button onClick={handleCopyVisits} disabled={selectedVisits?.length === 0} className="btn btn-primary btn-right"><Copy /> {t('protocols.visit.copyVisits_confirm')}</button>
                    <button type="button" className="btn btn-default btn-right" onClick={onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                </Col>
            </Row>
        </Grid>
    </>);
}