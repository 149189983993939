import React from 'react';
import { CopyVisits } from '../../../components/Protocols/Visit/CopyVisits';
import { useCopyVisits, useVisits } from './useVisits';

export const CopyVisitsContainer = ({ protocolId, onClose }) => {

    const [getVisits, data, setData, isLoading] = useVisits();
    const [copyVisits, isLoadingCopyVisits] = useCopyVisits();

    const handleSelectedProtocol = (id) => {
        if (id)
            getVisits(id);
        else
            setData([])
    }

    const handleCopyVisits = async (originProtocolId, selectedVisitIds) => {
        if (!originProtocolId || !selectedVisitIds || selectedVisitIds.length === 0)
            return;

        // Llamo al backend para copiar visitas. 
        var res = await copyVisits(protocolId, originProtocolId, selectedVisitIds);
        onClose();
    }

    return <CopyVisits
        onCopyVisits={handleCopyVisits}
        onSelectedProtocol={handleSelectedProtocol}
        onCancel={onClose}
        visits={data}
        isLoading={isLoading || isLoadingCopyVisits}
    />
}