import React, { useState } from 'react';

import { Col, Grid, Row } from 'react-bootstrap';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import { useTranslation } from 'react-i18next';
import { CustomRadio } from '../../CustomControls/CustomRadio';
import { Spinner } from '../../Utils/Loaders';

export const SaveAsTemplate = ({ isLoading, onCancel, onSaveAsTemplate }) => {
    const { t } = useTranslation();
    const [isCrossProtocol, setIsCrossProtocol] = useState(null);

    const handleCrossProtocolChange = (_controlName, selectedvalue) => {
        if (selectedvalue)
            setIsCrossProtocol(selectedvalue === "0" ? true : false);
    }

    if (isLoading)
        return <Spinner />

    return (<>
        <Grid fluid>
            <Row>
                <Col md={12}>
                    <CustomRadio
                        number="protocolOnly"
                        option={1}
                        name="cross"
                        onChange={handleCrossProtocolChange}
                        label={t('protocols.visit.formTemplate.saveAsTemplate_Button')}
                    />
                    <CustomRadio
                        number="crossProtocol"
                        option={0}
                        name="cross"
                        onChange={handleCrossProtocolChange}
                        label={t('protocols.visit.formTemplate.saveAsTemplateCross_Button')}
                    />
                </Col>
            </Row>
        </Grid>

        <Grid fluid>
            <Row>
                <Col md={12}>
                    <button type="button" className="btn btn-default" onClick={onCancel} >{t("buttons.cancel")}</button>
                    <button type="button" className="btn btn-success btn-right" disabled={isCrossProtocol === null} onClick={() => onSaveAsTemplate(isCrossProtocol)}>
                        {t("buttons.save")}
                    </button>
                </Col>
            </Row>
        </Grid>
    </>);
}