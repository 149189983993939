import axios from './index';

// Forms
export const getFormTemplates = async (protocolId) => {
    return axios.get(`api/v1/protocolTemplates/${protocolId}/visitsTemplates/formTemplates`);
};
export const getFormTemplate = (protocolId, formId) => {
    return axios.get(`api/v1/protocolTemplates/${protocolId}/visitsTemplates/formTemplates/${formId}`);
};
export const createFormTemplate = (protocolId, isCrossProtocol, template) => {
    return axios.post(`api/v1/protocolTemplates/${protocolId}/visitsTemplates/formTemplates/${isCrossProtocol}`, template);
};
export const removeFormTemplate = (protocolId, formId) => {
    return axios.delete(`api/v1/protocolTemplates/${protocolId}/visitsTemplates/formTemplates/${formId}`);
};