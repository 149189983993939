import React, { useEffect, useState } from 'react';
import { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink } from "react-router-dom";
import { exportToExcel } from '../../api/identity';
import { WithAuth } from '../../components/Authorization/WithAuth';
//Components
import Card from '../../components/Cards/Card';
import { CustomSearchBox } from '../../components/CustomControls/CustomSearchBox';
import ExportToExcel from "../../components/Export/ExportToExcel";
import { Add, Audit } from '../../components/Icons/Icons';
import { Users } from '../../components/Users/Users';
import AdminContext from '../Settings/AdminContext/AdminContext';
import { useUsers } from './Hooks';
import { AuditModal } from '../../components/AuditTrail/AuditModal';
import { AuditUserRoleContainer } from './Audit/AuditUserRole';

export const UsersContainer = () => {
    const { t } = useTranslation();
    const [query, setQuery] = useState({
        pagination: {
            page: 1,
            sizePerPage: 10,
            totalSize: 0
        },
        sort: {
            sortField: "FullName",
            sortOrder: "asc"
        },
        filters: {}
    });
    const [modal, setModal] = useState(null);
    const [users, isLoading, totalSize] = useUsers(query);
    //const { selectedTenant } = useContext(AdminContext);
    const context = useContext(AdminContext);

    useEffect(() => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.filters["TenantId"] = context?.selectedTenant != null ? context.selectedTenant.tenantId : null;
        setQuery(newQuery);

    }, [context]);

    const handleSearch = (q) => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.query = q;
        setQuery(newQuery);
    }

    const handleTableChange = (type, { page, sizePerPage, sortField, sortOrder }) => {
        var newQuery = {};
        if (type === 'pagination') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.pagination.page = page;
            newQuery.pagination.sizePerPage = sizePerPage;

            setQuery(newQuery);
        }

        if (type === 'sort') {
            newQuery = JSON.parse(JSON.stringify(query));

            newQuery.sort.sortField = sortField;
            newQuery.sort.sortOrder = sortOrder;

            setQuery(newQuery);
        }
    }

    //#region Audit
    const handleAuditUserRole = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditUserRoleContainer />
            </AuditModal>
        )
    }
    //#endregion

    return (
        <>
            {modal}
            <Card
                tabs
                header={
                    <Row>
                        <Col md={2}>
                            <WithAuth requiredPermission={["Users.Create"]}>
                                <NavLink to="/admin/users/create" className="btn btn-primary" activeClassName="active">
                                    <Add /> {t('security.users.add')}
                                </NavLink>
                            </WithAuth>
                        </Col>
                        <Col md={4} mdOffset={2}>
                            <CustomSearchBox handleSearch={handleSearch} placeholder={t("users.search")}></CustomSearchBox>
                        </Col>
                        <Col md={2}>
                            <ExportToExcel filters={query} exportToExcel={exportToExcel} />
                        </Col>
                        <Col md={2}>
                            <button className="btn btn-default btn-right" onClick={handleAuditUserRole} style={{ marginRight: '10px' }}>
                                <Audit /> {t("auditTrail.auditRole")}
                            </button>
                        </Col>
                    </Row>
                }
                content={
                    <Users
                        data={users}
                        onTableChange={handleTableChange}
                        query={query}
                        totalSize={totalSize}
                        isLoading={isLoading}
                    />
                }
            />
        </>
    );
}