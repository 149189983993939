import { useState } from "react";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { getUsersByIdAPI } from "../../../api/identity";
import { getAuditRolePermissionsAPI, getAuditStudyRolePermissionsAPI } from "../../../api/security";

export const useAuditRolePermissions = () => {
    const [audits, setAudits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (roleId) => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            var response = await getAuditRolePermissionsAPI(roleId);
            if (!response.data)
                return null;

            // Obtengo datos del usuario q realizó la acción
            var usersResponse = await getUsersByIdAPI(response.data.filter(x => x.actionBy).map(x => { return x.actionBy }))
            if (usersResponse.data) {
                response.data.forEach(item => {
                    item.actionByUser = usersResponse.data.find(x => x.userId == item.actionBy)
                });
            }

            setAudits(response.data);
            setIsLoading(false);
        }
        catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    return [fetchData, audits, isLoading];
}


export const useAuditStudyRolePermissions = () => {
    const [audits, setAudits] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const fetchData = async (roleId) => {
        setIsLoading(true);
        try {
            setIsLoading(true);
            var response = await getAuditStudyRolePermissionsAPI(roleId);
            if (!response.data)
                return null;

            // Obtengo datos del usuario q realizó la acción
            var usersResponse = await getUsersByIdAPI(response.data.filter(x => x.actionBy).map(x => { return x.actionBy }))
            if (usersResponse.data) {
                response.data.forEach(item => {
                    item.actionByUser = usersResponse.data.find(x => x.userId == item.actionBy)
                });
            }

            setAudits(response.data);
            setIsLoading(false);
        }
        catch (error) {
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    return [fetchData, audits, isLoading];
}