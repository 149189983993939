import React from 'react';
import { useTranslation } from 'react-i18next';
//Components
import { Col, Row } from 'react-bootstrap';
import { AuditRolePermissions } from '../../../components/AuditTrail/AuditRolePermissions';
import { CustomSelect } from '../../../components/CustomControls/CustomSelect';
import { useAuditStudyRolePermissions } from '../../Security/Audit/hooks';
import { useStudyRoles } from '../../Security/Authorization/useStudyRoles';

export const AuditRolePermissionsContainer = () => {
    const { t } = useTranslation();
    const [studyRoles] = useStudyRoles();
    const [getAudits, audits, isLoading] = useAuditStudyRolePermissions();

    const handleSearch = (_name, value) => {
        if (value)
            getAudits(value.id);
    }

    return (
        <>
            <Row>
                <Col md={4}>
                    <CustomSelect
                        name="studyRole"
                        placeholder={t("commons.select")}
                        onChange={handleSearch}
                        options={studyRoles}
                        getOptionLabel={x => x.name}
                    />
                </Col>
            </Row>
            <Row>
                <AuditRolePermissions
                    data={audits}
                    totalSize={audits?.length}
                    isLoading={isLoading}
                />
            </Row>
        </>
    );
}