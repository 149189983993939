import React, { useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getForms, getVisit, getVisitHistory, printVisit, publishVisit, removeVisit, sortForms } from '../../../api/protocol';
import { getExtraForms } from '../../../api/protocolsExtraForms';
import { ModalData } from '../../../components/Modals/ModalData';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { Actions } from '../../../components/Protocols/Actions';
import { ProtocolInfo } from '../../../components/Protocols/ProtocolInfo';
import { VisitExtraForms, VisitForms } from '../../../components/Protocols/Visit/Forms';
import { VisitHeader } from '../../../components/Protocols/Visit/Header';
import { VisitHistory } from '../../../components/Protocols/Visit/VisitHistory';
import { useProtocolApprovedVisits } from '../Hooks';
import { ProtocolProvider } from '../ProtocolContext/ProtocolProvider';
import { EditVisitModal } from './EditVisit';
import { FormRestrictionsContainer } from './FormRestrictions';
import { PublishModal, RemoveModal } from './RemoveVisit';
import { CopyFormsContainer } from './CopyForms';

export const Visit = (props) => {
    const { match: { params: { protocolId, visitId } } } = props;
    const [isLoading, setIsLoading] = useState(false);
    const visits = useProtocolApprovedVisits(protocolId);
    const [reload, setReload] = useState(false);
    const [reloadForms, setReloadForms] = useState(false);
    const [visit, setVisit] = useState(null);
    const { t } = useTranslation();

    //#region Effects
    useEffect(() => {
        let isSubscribed = true;
        const fetchVisitData = async () => {
            try {
                setIsLoading(true);
                const response = await getVisit(protocolId, visitId);
                if (isSubscribed)
                    setVisit(response.data);
                setIsLoading(false);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchVisitData();
        return () => isSubscribed = false;
    }, [protocolId, visitId, reload]);

    const [forms, setForms] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        const fetchFormsData = async () => {
            try {
                setIsLoading(true);
                setReloadForms(false);
                const response = await getForms(protocolId, visitId);
                if (isSubscribed)
                    setForms(response.data.sort((function (a, b) { return a.order - b.order; })));
                setIsLoading(false);
            }
            catch (error) {
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchFormsData();
        return () => isSubscribed = false;
    }, [protocolId, visitId, reloadForms]);

    const [visitHistory, setVisitHistory] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        const fetchVisitHistory = async () => {
            try {
                setIsLoading(true);
                setReload(false);
                const response = await getVisitHistory(protocolId, visitId);
                if (isSubscribed)
                    setVisitHistory(response.data);
                setIsLoading(false);
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchVisitHistory();
        return () => isSubscribed = false;
    }, [protocolId, visitId]);

    const [extraForms, setExtraForms] = useState([]);
    useEffect(() => {
        let isSubscribed = true;
        const fetchData = async () => {
            try {
                const response = await getExtraForms(protocolId, { filters: { extraFormType: 0 } });
                if (isSubscribed)
                    setExtraForms(response.data);
            }
            catch (error) {
                console.log(error);
            }
        }

        fetchData();

        return () => isSubscribed = false;
    }, [protocolId]);
    //#endregion

    //#region Handlers
    const handleSortEnd = (sortedForms) => {

        setIsLoading(true);
        sortForms(protocolId, visit.crossVersionId, sortedForms)
            .then(() => {
                setForms(sortedForms);
                setIsLoading(false);
            })
            .catch(error => {
                HandleApiError(error);
                setIsLoading(false);
            });
    };

    const [modal, setModal] = useState(null);
    const handleRemoveVisit = () => {
        setModal(<RemoveModal
            id={visitId}
            title={t("commons.areyousure")}
            onClose={() => { setModal(null); }}
            onConfirm={handleConfirmRemove} />)
    }
    const handleConfirmRemove = (visitId) => {
        removeVisit(protocolId, visitId)
            .then(res => {
                Success("protocolNotifications.visit_Removed");
                props.history.push(`/admin/protocols/${protocolId}`);
            }).catch(error => {
                console.error(error);
                HandleApiError(error);
                setModal(null);
            });
    }

    const handlePublishVisit = () => {
        setModal(<PublishModal
            id={visitId}
            title={t("protocols.visit.actionPublish")}
            subtitle={t("protocols.visit.visitVersionComments")} onClose={() => { setModal(null); }} onConfirm={handleConfirmPublish} />)
    }
    const handleConfirmPublish = (comments, visitId) => {
        publishVisit(protocolId, visitId, comments)
            .then(res => {
                Success("protocolNotifications.visit_Published");
                const visitResponse = res.data;
                const historyCopy = JSON.parse(JSON.stringify(visitHistory));
                let publishedVisit = historyCopy.find(x => x.crossVersionId === parseInt(visitId));
                publishedVisit.version = visitResponse.version;
                publishedVisit.versionComments = comments;
                setVisitHistory(historyCopy);
                setVisit(visitResponse);
                setModal(null);
            }).catch(error => {
                console.log(error);
                HandleApiError(error);
                setModal(null);
            });
    }

    const handleEditVisit = () => {
        setModal(<EditVisitModal
            protocolId={protocolId}
            visitId={visitId}
            hide={() => setModal(null)}
            submit={() => { setModal(null); setReload(true); }}
            isShowing={true} />);
    }

    const handlePrintVisit = async () => {
        try {
            var response = await printVisit(protocolId, visitId);

            const blob = new Blob([response.data], { type: "application/pdf" });
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.setAttribute('download', visit.title);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
        }
        catch (error) {
            HandleApiError(error);
            console.log(error);
        };
    }
    //#endregion

    //#region FormRestrictions
    const handleFormRestrictions = () => {
        setModal(<ModalData
            isShowing={true}
            className=""
            hide={() => setModal(null)}
            title={t("protocols.visitTracking.formRestrictions.title")}
        >
            <FormRestrictionsContainer
                forms={forms}
                protocolId={protocolId}
                onClose={() => setModal(null)}
                onCancel={() => setModal(null)}
            />
        </ModalData>);
    }
    //#endregion

    //#region CopyForms
    const handleCopyForms = () => {
        setModal(<ModalData
            isShowing={true}
            className=""
            hide={() => { setReloadForms(true); setModal(null) }}
            title={t("protocols.visit.copyForms_title")}
        >
            <CopyFormsContainer
                protocolId={protocolId}
                visitId={visitId}
                crossVersionId={visit?.crossVersionId}
                onSubmitted={() => { setReloadForms(true); setModal(null) }}
                onCancel={() => setModal(null)}
            />
        </ModalData>);
    }
    //#endregion

    return (
        <ProtocolProvider protocolId={protocolId}>
            <Grid fluid>
                <Row>
                    <Col md={12}>
                        <ProtocolInfo />
                    </Col>
                </Row>
                <Row>
                    <Col md={2}>
                        <Actions
                            visit={visit}
                            onBack={() => props.history.push(`/admin/protocols/${protocolId}/section/visitstemplate`)}
                            onEdit={handleEditVisit}
                            onPublish={
                                (visit?.version % 1) !== 0 ?
                                    handlePublishVisit
                                    : null
                            }
                            onRemove={handleRemoveVisit}
                            onPrint={handlePrintVisit}
                            onFormRestrictions={handleFormRestrictions}
                        />
                    </Col>
                    <Col md={10}>
                        <Row>
                            <Col md={12}>
                                {modal}
                                <VisitHeader
                                    visit={visit}
                                    visits={visits}
                                    onRemoveVisit={handleRemoveVisit}
                                    onPublishVisit={handlePublishVisit}
                                    onEditVisit={handleEditVisit}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col md={4}>
                                <VisitForms
                                    isLoading={isLoading}
                                    forms={forms}
                                    protocolId={protocolId}
                                    visitId={visit?.crossVersionId}
                                    onSortEnd={handleSortEnd}
                                    onCopyForms={handleCopyForms}
                                />
                                <VisitExtraForms
                                    isLoading={isLoading}
                                    forms={extraForms}
                                    protocolId={protocolId}
                                />
                            </Col>
                            <Col md={8}>
                                <VisitHistory
                                    visitHistory={visitHistory}
                                    onRemoveVisit={handleRemoveVisit}
                                    onPublishVisit={handlePublishVisit}
                                    onEditVisit={handleEditVisit}
                                />
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid>
        </ProtocolProvider>
    );
}