import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    changeTypeVisitTrackingAPI, changeVisitTrackingTypeAPI,
    discontinuationVisitTracking,
    endPatientInProtocolVisitTrackingAPI,
    exportVisitTrackingAPI,
    getVisitTrackingAttachmentFormEndpoint,
    getVisitTrackingForm, getVisitTrackingForms, lostToFollowUpVisitTracking, publishVisitTracking, reSyncVisitTrackingAPI,
    removeVisitTrackingAPI,
    returnDrugsVisitTracking,
    rollbackVisitStatusAPI,
    saveUploadVisitTrackingAPI,
    saveVisitTracking, screenFailureVisitTracking, skipVisitTracking,
    uploadVisitTrackingAttachmentFormEndpoint,
    withoutDrugsVisitTracking
} from '../../../api/protocol';
import { AuditModal, CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization';
import { DocumentViewer } from '../../../components/CustomControls/DocViewer/DocumentViewer';
import { useFiles } from '../../../components/CustomControls/Files/Hooks';
import { DigitalSign } from '../../../components/DigitalSign/DigitalSign';
import { SignWithOutInfo } from '../../../components/DigitalSign/Sign';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { MedicalRecord, OnSite, Phone } from '../../../components/Icons/Icons';
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { ModalSpinner } from '../../../components/Modals/ModalSpinner';
import { HandleApiError, HandleMessageError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { PatientStatusInProtocol } from '../../../components/Protocols/Patients';
import { FormActions, FormMenu, VisitStatus } from '../../../components/Protocols/Tracking/Forms';
import { ChangeVisitType, ConfirmModal, LostToFollowUp, ScreenFailure, SkipVisitTracking } from '../../../components/Protocols/Tracking/Modals';
import { Slider } from '../../../components/Slider/Slider';
import { Spinner } from '../../../components/Utils/Loaders';
import { getValidationSchema } from '../../../components/Validations/FormValidationSchema';
import { formStatus, visitStatus, visitTypeEnum } from "../../../variables/Enums";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ModalDigitalSign } from '../../DigitalSign/ModalDigitalSign';
import { EntryItemsListTabs } from '../../MedicalRecords/MedicalRecordItems/EntryItemsList';
import DocumentNotesContainer from '../DocumentNotes/DocumentNotes';
import { EvolutionsContainer } from '../Evolutions/Evolutions';
import { useAudits } from '../Hooks';
import MonitorNotesContainer from '../MonitorNotes/MonitorNotes';
import ProtocolContext from '../ProtocolContext/ProtocolContext';

function search(array, condition) {
    if (array.length === 0) {
        return [];
    }
    const [head, ...tail] = array;
    if (condition(head)) {
        return [head, ...search(tail, condition)];
    }
    return search(tail, condition);
}

const getVisitType = (visitType) => {
    switch (visitType) {
        case visitTypeEnum.OnSite:
            return <OnSite />
        case visitTypeEnum.Remote:
            return <Phone />
        default:
            return "";
    }
}

export const VisitTracking = ({ protocolId, patientId, visitId }) => {
    let history = useHistory();
    const { t } = useTranslation();
    const [isAuthorized, isAuthLoading, currentUser] = useAuthProtocol(protocolId);
    const settingsStore = useSelector(state => state.settings);
    const context = useContext(ProtocolContext);
    const [fileData, getBlobAPI, postAPI] = useFiles();

    const [modal, setModal] = useState(null);
    const [showHistory, setShowHistory] = useState(false);
    const [hasVisitTracking, setHasVisitTracking] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [forms, setForms] = useState([]);
    const [visitTracking, setVisitTracking] = useState(null);
    const [signStatus, setSignStatus] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [formsWithErrors, setFormsWithErrors] = useState([]);
    const [patientInfo, setPatientInfo] = useState(null);
    const [, , getLastChange] = useAudits("VisitTrackingForm", selectedForm?.id, hasVisitTracking);
    const [, , getLastVisitTrackingChange] = useAudits("VisitTracking", visitTracking?.id, hasVisitTracking);
    const [reload, setReload] = useState(false);
    const [evolutions, setEvolutions] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            setReload(false);
            try {
                const response = await getVisitTrackingForms(protocolId, visitId, patientId);

                var visitTracking = response.data;
                var urlParams = new URLSearchParams(window.location.search);
                let formAux = visitTracking.forms[0];
                if (urlParams.has('formId') && visitTracking.forms.find(x => x.id == urlParams.get('formId'))) {
                    formAux = visitTracking.forms.find(x => x.id == urlParams.get('formId'));
                }

                setEvolutions(visitTracking.visit.evolutions);
                //setSelectedForm(formAux);
                addHidePropertyToFields(formAux);
                setForms(visitTracking.forms);
                setVisitTracking(visitTracking.visit);
                setHasVisitTracking(visitTracking.hasVisitTracking);
                setSignStatus(visitTracking.signStatus);
                setPatientInfo({
                    patientNumber: visitTracking.patientNumber,
                    patientInProtocolStatus: visitTracking.patientInProtocolStatus,
                    medicalRecordNumber: visitTracking.medicalRecordNumber// visitTracking.visit.patientId
                });
                setIsLoading(false);
            }
            catch (error) {
                console.log(error);
                HandleApiError(error);
                setIsLoading(false);
            }
        }

        fetchData();
    }, [visitId, reload]);

    //#region private Functions

    /**
     * Setea visibilidad de campos del formulario, teniendo en cuenta los Si/No Condicionales (booleanFields)
     * @param {PatientForm} form 
     */
    function manageConditionalFieldsInForm(form) {
        let formAux = JSON.parse(JSON.stringify(form));
        let fields = formAux.fields.map(x => {
            if (typeof x.hide === "undefined")
                return Object.assign({}, x, { hide: false });
            return x;
        });

        // Resuelvo conditionals.
        var conditionalFields = fields.filter(x => (x._t === "BooleanField" || x._t === "PayableField") && x.conditional);
        conditionalFields.forEach(conditionalField => {
            fields.filter(field => conditionalField.conditional.includes(field.id))
                .forEach((fieldWithCondition) => {
                    if (conditionalField.value === false)
                        fieldWithCondition.hide = true;
                    if (conditionalField.value === true)
                        fieldWithCondition.hide = false;
                });
        });

        formAux.fields = fields;

        return formAux;
    }

    function addHidePropertyToFields(form) {
        setSelectedForm(manageConditionalFieldsInForm(form));
    }

    const handleSelectForm = (formId, isDirty) => {
        if (!isDirty || (isDirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setIsLoading(true);
            let selectedForms = forms.filter(x => { return x.id === formId });
            let formAux = JSON.parse(JSON.stringify(selectedForms.length > 0 ? selectedForms[0] : {}));
            formAux.text = selectedForm.text;
            addHidePropertyToFields(formAux)
            //setSelectedForm(JSON.parse(JSON.stringify(formAux)));
            setIsLoading(false);
        }
    }

    const _closeModal = () => { setModal(null); }

    const _updateData = (visit, updatedForm) => {
        if (!updatedForm || !updatedForm.id) {
            window.location.reload(false)
            return;
        }

        updatedForm.text = "";
        let formsCopy = JSON.parse(JSON.stringify(forms));
        var index = formsCopy.findIndex(x => x.id === updatedForm.id);
        formsCopy.splice(index, 1, updatedForm); //Reemplazo form.
        setVisitTracking(visit);
        setEvolutions(visit.evolutions);
        setForms(formsCopy);
        //setSelectedForm(updatedForm);
        addHidePropertyToFields(updatedForm)

        _closeModal();
        Success("protocolNotifications.visitTracking_FormSaved");
    }

    const _saveVisit = async (formVisitTracking, formikProps) => {
        try {
            if (formikProps.isSubmitting)
                return;

            formikProps.setSubmitting(true);
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            const res = await saveVisitTracking(protocolId, visitId, patientId, patientInfo.medicalRecordNumber, formVisitTracking);
            const form = res.data.form;
            const visit = res.data.visit;

            // Upload attachments if any.
            const fieldsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "FileUploadField" && x.value?.file);
            const componentsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "ArrayField")
                .map(array => {
                    return array.components.map(component => {
                        return component.fields.map(field => field._t === "FileUploadField" && field !== null && field).flat();
                    }).flat();
                }).flat().filter(x => x.value?.file); // filtro por los nuevos solamente. 
            const files = fieldsWithFiles.concat(componentsWithFiles);

            if (files && files.length > 0) {
                try {
                    const attachs = files.map(x => x.value);
                    postAPI(attachs, uploadVisitTrackingAttachmentFormEndpoint(protocolId, visitId, form.id, patientId, patientInfo.medicalRecordNumber))
                        .then(() => {
                            if (visit.numberInProtocol !== patientId) {
                                history.push(`/admin/protocols/${protocolId}/visitTracking/${visitId}/patient/${visit?.numberInProtocol}`);
                            }
                            getVisitTrackingForm(protocolId, visitId, form.id)
                                .then(resForm => {
                                    // Actualizo el form en FrontEnd.
                                    _updateData(visit, resForm?.data);
                                }).catch((error) => {
                                    console.log(error)
                                    window.location.reload(false)
                                })
                        }).catch((error) => { console.log(error) });
                }
                catch (error) {
                    console.log(error.response);
                }
            }
            else {
                if (visit.numberInProtocol !== patientId) {
                    history.push(`/admin/protocols/${protocolId}/visitTracking/${visitId}/patient/${visit?.numberInProtocol}`);
                }
                _updateData(visit, form);
            }
        }
        catch (error) {
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
        finally {
            formikProps.setSubmitting(false);
        }
    }

    const _publishVisit = async (digitalSign, actions) => {
        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            const res = await publishVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setVisitTracking(res.data);
            actions.setSubmitting(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            _closeModal();
            actions.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleSaveVisit = (formVisitTracking, formikProps) => {
        if (visitTracking.status !== visitStatus.Started || formVisitTracking.status !== formStatus.Initial) {
            setModal(<CreateAuditModal
                onClose={_closeModal}
                onSave={(reason, comments) => _saveVisit({ patientForm: formVisitTracking, audit: { reason: reason, comments: comments } }, formikProps)}
            />)
        }
        else {
            _saveVisit({ patientForm: formVisitTracking }, formikProps);
        }
    }

    const _saveUploadVisit = async (formVisitTracking, formikProps) => {
        try {
            if (formikProps.isSubmitting)
                return;

            formikProps.setSubmitting(true);
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            const res = await saveUploadVisitTrackingAPI(protocolId, visitId, patientId, patientInfo.medicalRecordNumber, formVisitTracking);
            const form = res.data.form;
            const visit = res.data.visit;

            // Upload attachments. 
            const fieldsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "FileUploadField" && x.value.file);
            const componentsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "ArrayField")
                .map(array => {
                    return array.components.map(component => {
                        return component.fields.map(field => field._t === "FileUploadField" && field !== null && field).flat();
                    }).flat();
                }).flat().filter(x => x.value?.file); // filtro por los nuevos solamente. 
            const files = fieldsWithFiles.concat(componentsWithFiles);

            if (files && files.length > 0) {
                try {
                    const attachs = files.map(x => x.value);
                    postAPI(attachs, uploadVisitTrackingAttachmentFormEndpoint(protocolId, visitId, form.id, patientId, patientInfo.medicalRecordNumber))
                        .then(() => {
                            getVisitTrackingForm(protocolId, visitId, form.id)
                                .then(resForm => {
                                    // Actualizo el form en FrontEnd.
                                    _updateData(visit, resForm?.data);
                                }).catch((error) => {
                                    console.log(error)
                                    window.location.reload(false)
                                })
                        }).catch((error) => { console.log(error) });
                }
                catch (error) {
                    console.log(error.response);
                }
            }
            else {
                _updateData(visit, form);
            }
        }
        catch (error) {
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
        finally {
            formikProps.setSubmitting(false);
        }
    }
    const handleSaveUploadVisit = (formVisitTracking, formikProps) => {
        if (visitTracking.status !== visitStatus.Started || formVisitTracking.status !== formStatus.Initial) {
            setModal(<CreateAuditModal
                onClose={_closeModal}
                onSave={(reason, comments) => _saveUploadVisit({ patientForm: formVisitTracking, audit: { reason: reason, comments: comments } }, formikProps)}
            />)
        }
        else {
            _saveUploadVisit({ patientForm: formVisitTracking }, formikProps);
        }
    }

    const handleSubmitFormik = (_formVisitTracking, actions) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); actions.setSubmitting(false); }}
                onSubmit={(pin) => _publishVisit(pin, actions)}
            />);

        else
            _publishVisit({ password: "" }, actions)
    }

    const handleSubmitForm = (evt, formikBag) => {
        evt.preventDefault();
        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            // Valido errores en todos los formularios. 
            let withErrors = [];
            let showErrorValidations = false;
            forms.forEach(form => {
                if (form.extraFormType !== 0 && !getValidationSchema().isValidSync(manageConditionalFieldsInForm(form))) {
                    withErrors.push({ id: form.id, title: form.title });
                    if (form.id === formikBag.values.id)
                        showErrorValidations = true;
                }
            });

            if (withErrors.length > 0) {
                setFormsWithErrors(withErrors);
                HandleMessageError(t("protocolErrors.visitTracking_requiredFieldsError", { requiredFields: withErrors.map(e => e.title).join(' - ') }));
                if (showErrorValidations)
                    formikBag.submitForm();
            } else {
                formikBag.submitForm();
            }
        }
    }

    const handleSkipVisit = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(<SkipVisitTracking
                onClose={_closeModal}
                onConfirm={() => confirmSkipVisit(formikBag)}
            />)
        }
    }

    const confirmSkipVisit = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishSkipVisit(pin, formikBag)}
            />);

        else
            _publishSkipVisit({ password: "" }, formikBag)
    }

    const _publishSkipVisit = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await skipVisitTracking(protocolId, visitId, patientId, digitalSign);
            setIsLoading(false);
            _closeModal();
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
            setIsLoading(false);
        }
    }

    const handleScreenFailure = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(<ScreenFailure
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmScreenFailure(formikBag)}
            />)
        }
    }

    const confirmScreenFailure = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishScreenFailure(pin, formikBag)}
            />);
        else
            _publishScreenFailure({ password: "" }, formikBag)
    }

    const _publishScreenFailure = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await screenFailureVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleLostToFollowUp = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(<LostToFollowUp
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmLostToFollowUp(formikBag)}
            />)
        }
    }

    const confirmLostToFollowUp = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishLostToFollowUp(pin, formikBag)}
            />);
        else
            _publishLostToFollowUp({ password: "" }, formikBag)
    }

    const _publishLostToFollowUp = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await lostToFollowUpVisitTracking(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleDiscontinuation = async (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_discontinuation_title")}
            description={t("protocolNotifications.visitTracking_discontinuation_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _setDiscontinuation(formikBag)}
        />)
    }

    const _setDiscontinuation = async (formikBag) => {
        try {
            setIsLoading(true);
            // Marco visita como Discontinuación y sincronizo. 
            const res = await discontinuationVisitTracking(protocolId, visitId, patientId, { password: "" });
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);

            //// Creo visita de Terminación Temprana.
            //const res = await createVisitTrackingExtra(protocolId, patientInfo.medicalRecordNumber, { type: "EarlyCompletion" });
            //history.push(`/admin/protocols/${protocolId}/visitTrackingExtra/${res.data.id}/patient/${res?.data?.numberInProtocol}`);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleWithoutDrugs = async (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_withoutDrugs_title")}
            description={t("protocolNotifications.visitTracking_withoutDrugs_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _setWithoutDrugs(formikBag)}
        />)
    }

    const _setWithoutDrugs = async (formikBag) => {
        try {
            setIsLoading(true);
            const res = await withoutDrugsVisitTracking(protocolId, visitId, patientId);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleReturnDrugs = async (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_returnDrugs_title")}
            description={t("protocolNotifications.visitTracking_returnDrugs_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _setReturnDrugs(formikBag)}
        />)
    }

    const _setReturnDrugs = async (formikBag) => {
        try {
            setIsLoading(true);
            const res = await returnDrugsVisitTracking(protocolId, visitId, patientId);
            _closeModal();
            setReload(true);
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }


    const handleEndPatientInProtocol = (formikBag) => {
        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(
                <ModalConfirm
                    onConfirm={() => confirmEndPatientInProtocol(formikBag)}
                    onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
                    title={t("protocolNotifications.visitTracking_endPatientInProtocol_title")}
                    description={t("protocolNotifications.visitTracking_endPatientInProtocol_description")}
                />);
        }
    }
    const confirmEndPatientInProtocol = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishEndPatientInProtocol(pin, formikBag)}
            />);
        else
            _publishEndPatientInProtocol({ password: "" }, formikBag)
    }
    const _publishEndPatientInProtocol = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await endPatientInProtocolVisitTrackingAPI(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleRollbackVisitStatus = (formikBag) => {
        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(
                <ModalConfirm
                    onConfirm={() => confirmRollbackVisitStatus(formikBag)}
                    onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
                    title={t("protocolNotifications.visitTracking_rollbackVisitStatus_title")}
                    description={t("protocolNotifications.visitTracking_rollbackVisitStatus_description")}
                />);
        }
    }
    const confirmRollbackVisitStatus = (formikBag) => {
        setModal(<CreateAuditModal
            onClose={_closeModal}
            onSave={(reason, comments) => _publishRollbackVisitStatus({ audit: { reason: reason, comments: comments } }, formikBag)}
        />)
    }
    const _publishRollbackVisitStatus = async (audit, formikBag) => {
        try {
            setIsLoading(true);
            const res = await rollbackVisitStatusAPI(protocolId, visitId, patientId, audit);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_rollbackDone");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }


    const handleAuditTrail = () => {
        setModal(
            <AuditModal
                onClose={_closeModal}
                title={t("auditTrail.audit")}
            >
                {t("auditTrail.field")}:
                <AuditTrail entity="VisitTrackingForm" entityId={selectedForm.id} fields={selectedForm && selectedForm.fields} />

                {t("auditTrail.evolution")}:
                <AuditTrail entity="VisitTracking" entityId={visitTracking.id} />
            </AuditModal>
        )
    }

    const handleViewFile = async (attachName) => {
        try {
            await getBlobAPI(attachName, getVisitTrackingAttachmentFormEndpoint(protocolId, visitId, selectedForm.id, attachName));
        }
        catch (error) {
            console.error(error);
            HandleApiError(error);
        }
    }

    const handleReSync = () => {
        setModal(<ConfirmModal
            onClose={_closeModal}
            onConfirm={_digitalSign}
            title={t("protocolNotifications.visitTracking_reSync_title")}
            description={t("protocolNotifications.visitTracking_reSync_description")}
        />);
    }

    const _digitalSign = () => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={_closeModal}
                onSubmit={(pin) => confirmReSync(pin)}
            />);

        else
            confirmReSync({ password: "" })
    }

    const confirmReSync = async (digitalSign) => {
        try {
            setIsLoading(true);
            const res = await reSyncVisitTrackingAPI(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            Success("protocolNotifications.visitTracking_reSyncOk");
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleChangeVisitTrackingType = (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_changeVisitTrackingType_title")}
            description={t("protocolNotifications.visitTracking_changeVisitTrackingType_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _setChangeVisitTrackingType(formikBag)}
        />);
    }

    const _setChangeVisitTrackingType = async (formikBag) => {
        try {
            setIsLoading(true);
            await changeVisitTrackingTypeAPI(protocolId, visitId, patientId, visitTracking.entryId, visitTracking.id);
            Success("protocolNotifications.visitTracking_changeTypeOk");
            history.push(`/admin/protocols/${protocolId}/visitTrackingExtra/${visitTracking.id}/patient/${patientId}`);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleChangeType = (formikBag) => {
        setModal(<ChangeVisitType
            visit={t("protocolNotifications.visitTracking_changeType_title")}
            onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
            onChange={(type) => _changeType(type, formikBag)}
        />)
    }

    const _changeType = async (type, formikBag) => {
        try {
            setIsLoading(true);
            const res = await changeTypeVisitTrackingAPI(protocolId, visitId, patientId, type);
            _closeModal();
            //setReload(true);
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleRemoveVisit = (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_removeVisit_title")}
            description={t("protocolNotifications.visitTracking_removeVisit_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _confirmRemoveVisit()}
        />)
    }

    const _confirmRemoveVisit = async () => {
        try {
            setIsLoading(true);
            await removeVisitTrackingAPI(protocolId, visitId, patientId);
            Success("protocolNotifications.visitTracking_removeVisitOk");
            history.push(`/admin/protocols/${protocolId}`);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }

    const _export = async (pin) => {
        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            const response = await exportVisitTrackingAPI(protocolId, visitTracking?.id, patientId, pin?.password);
            //const blob = new Blob([response.data], { type: "text/html" });
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            var fileName = "PatientVisit.zip";
            var disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, '');
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            _closeModal();
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }
    const handleExport = async () => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); }}
                onSubmit={(pin) => _export(pin)}
            />);

        else
            _export("")
    }

    //#endregion

    if (!visitTracking || !selectedForm || !forms || isLoading || settingsStore.isLoading || isAuthLoading)
        return <Spinner />;

    const isDisabled = !isAuthorized(["VisitTracking.Add"]) || context.isDisabled || !hasVisitTracking;
    const isUploadEnabled = isAuthorized(["VisitTracking.UploadFile"]) && !context.isDisabled && hasVisitTracking;
    const showStatus = ((!isAuthorized(["VisitTracking.Add"]) && visitTracking?.status !== visitStatus.Started) || isAuthorized(["VisitTracking.Add"]));

    return (
        <>
            {modal}
            <DocumentViewer
                file={fileData?.blob}
                fileName={fileData?.fileName}
                inModal={true}
                isLoading={fileData?.isLoading}
            />
            <Formik
                initialValues={selectedForm}
                validationSchema={selectedForm.extraFormType !== 0 && getValidationSchema}
                onSubmit={handleSubmitFormik}
                enableReinitialize={true}
                validateOnBlur={true}
                validateOnChange={true}
            >
                {props => (
                    <form onReset={props.handleReset} onSubmit={(evt) => handleSubmitForm(evt, props)}>
                        <Row>
                            <Col md={4} lg={3} className="sticky">
                                <Row>
                                    <Col md={12}>
                                        <FormMenu
                                            forms={forms}
                                            onClick={(formId) => handleSelectForm(formId, props.dirty)}
                                            selectedFormId={selectedForm?.id}
                                            formsWithErrors={formsWithErrors}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormActions
                                            visit={visitTracking}
                                            hasVisitTracking={hasVisitTracking}
                                            forms={forms}
                                            patientInProtocolStatus={patientInfo?.patientInProtocolStatus}
                                            onBack={(evt) => { evt.preventDefault(); history.push(`/admin/protocols/${protocolId}/section/visittracking`) }} // history.goBack();
                                            onSave={() => handleSaveVisit(props.values, props)}
                                            onSaveUpload={() => handleSaveUploadVisit(props.values, props)}
                                            onSkip={() => handleSkipVisit(props)}
                                            onExport={handleExport}
                                            onLostToFollowUp={() => handleLostToFollowUp(props)}
                                            onScreenFailure={() => handleScreenFailure(props)}
                                            onDiscontinuation={() => handleDiscontinuation(props)}
                                            onWithoutDrugs={() => handleWithoutDrugs(props)}
                                            onReturnDrugs={() => handleReturnDrugs(props)}
                                            onChangeType={() => handleChangeType(props)}
                                            onEndPatientInProtocol={() => handleEndPatientInProtocol(props)}
                                            onRollbackVisitStatus={() => handleRollbackVisitStatus(props)}
                                            onChangeVisitTrackingType={() => handleChangeVisitTrackingType(props)}
                                            onReSync={handleReSync}
                                            onAuditTrail={handleAuditTrail}
                                            onBackToMR={() => history.push(`/admin/medicalRecords/${patientInfo?.medicalRecordNumber}`)}
                                            onRemove={() => handleRemoveVisit(props)}
                                            isSubmitting={props.isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} lg={5}>
                                {showStatus && visitTracking && <VisitStatus status={visitTracking?.status} date={visitTracking?.created} />}
                                <DynamicForm
                                    {...props}
                                    currentUser={currentUser?.sub}
                                    patientId={visitTracking.patientId}
                                    patientNumber={patientInfo.patientNumber}
                                    visitId={visitId}
                                    protocolId={protocolId}
                                    onDownload={handleViewFile}
                                    isDisabled={isDisabled}
                                    isUploadEnabled={isUploadEnabled}
                                    getLastChange={getLastChange}
                                />
                                {
                                    !settingsStore.settings.digitalSignEnabled && (visitTracking?.status === visitStatus.Completed || visitTracking?.status === visitStatus.Skipped || visitTracking?.status === visitStatus.Failed) &&
                                    <SignWithOutInfo signedBy={selectedForm.createdById} created={selectedForm.created} locale={true} />
                                }
                                <WithAuth protocolId={protocolId} requiredPermission={["InternalNotes.List"]}>
                                    {
                                        hasVisitTracking &&
                                        <DocumentNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm.id}
                                            visitId={visitId}
                                            patientId={patientInfo.patientNumber}
                                            type="VisitTracking"
                                        />
                                    }
                                </WithAuth>
                            </Col>
                            <Col md={4} lg={4} className="sticky">
                                <button type="button"
                                    className="btn btn-primary btn-fill btn-full"
                                    onClick={() => { setShowHistory(!showHistory) }}
                                    style={{ marginBottom: '22px', textAlign: 'center' }}>
                                    <MedicalRecord /> {t("protocols.visitTracking.viewMR")}
                                </button>

                                <EvolutionsContainer
                                    {...props}
                                    visitType="VisitTracking"
                                    protocolId={protocolId}
                                    visitId={visitId}
                                    patientId={patientId}
                                    medicalRecordNumber={patientInfo.medicalRecordNumber}
                                    visitTrackingStatus={visitTracking.status}
                                    data={evolutions}
                                    audit={getLastVisitTrackingChange("VisitText")}
                                    isDisabled={isDisabled}
                                    title={<>{getVisitType(visitTracking.visitType)} {t("protocols.visitTracking.patientEvolution")}</>}
                                    subtitle={<><PatientStatusInProtocol status={patientInfo.patientInProtocolStatus} /> {patientInfo.patientNumber}</>}
                                />
                                {
                                    !settingsStore.settings.digitalSignEnabled && (visitTracking?.status === visitStatus.Completed || visitTracking?.status === visitStatus.Skipped || visitTracking?.status === visitStatus.Failed) &&
                                    <SignWithOutInfo signedBy={visitTracking.createdById} created={visitTracking.created} locale={true} />
                                }
                                {
                                    settingsStore.settings.digitalSignEnabled &&
                                    <DigitalSign signedBy={visitTracking.digitalSign?.signedBy} signStatus={signStatus} signedDate={visitTracking.digitalSign?.signedDate} />
                                }
                                <WithAuth protocolId={protocolId} requiredPermission={["ExternalNotes.List"]}>
                                    {
                                        hasVisitTracking &&
                                        <MonitorNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm.id}
                                            visitId={visitId}
                                            patientId={patientInfo.patientNumber}
                                            type="VisitTracking"
                                        />
                                    }
                                </WithAuth>
                            </Col>
                            <Slider isOpen={showHistory}>
                                <Grid fluid>
                                    <button type="button"
                                        className="btn btn-primary btn-fill btn-full"
                                        onClick={() => { setShowHistory(!showHistory) }}
                                        style={{ margin: '22px 0', textAlign: 'center' }}>
                                        <MedicalRecord /> {t("protocols.visitTracking.hideMR")}
                                    </button>
                                    <EntryItemsListTabs medicalRecordNumber={visitTracking.patientId} inProtocol />
                                </Grid>
                            </Slider>
                        </Row>
                    </form>
                )}
            </Formik>
        </>
    );
}