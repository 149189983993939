import { Formik } from 'formik';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Grid, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { endPatientInProtocolVisitTrackingExtraAPI, exportVisitTrackingAPI, getVisitTrackingAttachmentFormEndpoint, getVisitTrackingExtraForms, getVisitTrackingForm, publishVisitTrackingExtraForm, removeVisitTrackingExtraAPI, rollbackVisitStatusExtraAPI, saveVisitTrackingExtraForm, screenFailureVisitTrackingExtra, uploadVisitTrackingExtraAttachmentFormEndpoint } from '../../../api/protocol';
import { AuditModal, CreateAuditModal } from '../../../components/AuditTrail/AuditModal';
import { WithAuth } from '../../../components/Authorization/WithAuthProtocol';
import { useAuthProtocol } from '../../../components/Authorization/useAuthorization';
import { DocumentViewer } from '../../../components/CustomControls/DocViewer/DocumentViewer';
import { useFiles } from '../../../components/CustomControls/Files/Hooks';
import { DigitalSign } from '../../../components/DigitalSign/DigitalSign';
import { SignWithOutInfo } from '../../../components/DigitalSign/Sign';
import { DynamicForm } from '../../../components/DynamicForm/DynamicForm';
import { MedicalRecord } from '../../../components/Icons/Icons';
import { ModalConfirm } from '../../../components/Modals/ModalConfirm';
import { ModalSpinner } from '../../../components/Modals/ModalSpinner';
import { HandleApiError } from '../../../components/Notifications/APIErrorHandler';
import { Success } from '../../../components/Notifications/Notifications';
import { FormActions } from '../../../components/Protocols/ExtraVisits/Forms';
import { FormMenu, VisitStatus } from '../../../components/Protocols/Tracking/Forms';
import { ScreenFailure } from '../../../components/Protocols/Tracking/Modals';
import { Slider } from '../../../components/Slider/Slider';
import { Spinner } from '../../../components/Utils/Loaders';
import { getValidationSchema } from '../../../components/Validations/FormValidationSchema';
import { formStatus, visitStatus as visitStatusEnum } from "../../../variables/Enums";
import { AuditTrail } from "../../Admin/AuditTrail";
import { ModalDigitalSign } from '../../DigitalSign/ModalDigitalSign';
import { EntryItemsListTabs } from '../../MedicalRecords/MedicalRecordItems/EntryItemsList';
import DocumentNotesContainer from '../DocumentNotes/DocumentNotes';
import { EvolutionsContainer } from '../Evolutions/Evolutions';
import { useAudits } from '../Hooks';
import MonitorNotesContainer from '../MonitorNotes/MonitorNotes';
import ProtocolContext from '../ProtocolContext/ProtocolContext';
import { getVisitTypeTitle } from '../Tracking/Services';

export const ExtraVisitTracking = ({ protocolId, patientId, visitId }) => {
    let history = useHistory();
    const [showHistory, setShowHistory] = useState(false);
    const [isAuthorized] = useAuthProtocol(protocolId);
    const settingsStore = useSelector(state => state.settings);
    const context = useContext(ProtocolContext);
    const [isAuditLoading, , getLastVisitTrackingChange] = useAudits("VisitTrackingExtra", visitId);
    const [isLoading, setIsLoading] = useState(false);
    const [fileData, getBlobAPI, postAPI] = useFiles();

    const [forms, setForms] = useState([]);
    const [signStatus, setSignStatus] = useState(null);
    const [visitTracking, setVisitTracking] = useState(null);
    const [selectedForm, setSelectedForm] = useState(null);
    const [modal, setModal] = useState(null);
    const [patientInfo, setPatientInfo] = useState(null);
    const { t } = useTranslation();
    const [evolutions, setEvolutions] = useState([]);

    useEffect(() => {
        const fetchData = () => {
            setIsLoading(true);

            getVisitTrackingExtraForms(protocolId, visitId, patientId)
                .then(response => {
                    var visitTracking = response.data;

                    if (visitTracking.forms && visitTracking.forms.length > 0) {
                        let formAux = visitTracking.forms[0];
                        var urlParams = new URLSearchParams(window.location.search);
                        if (urlParams.has('formId') && visitTracking.forms.find(x => x.id == urlParams.get('formId'))) {
                            formAux = visitTracking.forms.find(x => x.id == urlParams.get('formId'));
                        }
                        formAux.text = visitTracking.visit.text;
                        setSelectedForm(formAux);
                    }
                    setEvolutions(visitTracking.visit.evolutions);
                    setSignStatus(visitTracking.signStatus);
                    setForms(visitTracking.forms);
                    setVisitTracking(visitTracking.visit);
                    setPatientInfo({
                        patientNumber: visitTracking.patientNumber,
                        patientInProtocolStatus: visitTracking.patientInProtocolStatus,
                        medicalRecordNumber: visitTracking.medicalRecordNumber
                    });
                    setIsLoading(false);
                })
                .catch(error => {
                    console.log(error);
                    HandleApiError(error);
                    setIsLoading(false);
                })
        }

        fetchData();
    }, [protocolId, visitId, patientId]);

    const _closeModal = () => { setModal(null); }

    const _updateData = (visit, updatedForm) => {
        if (!updatedForm || !updatedForm.id) {
            window.location.reload(false)
            return;
        }

        updatedForm.text = visit.text;
        let formsCopy = JSON.parse(JSON.stringify(forms));
        var index = formsCopy.findIndex(x => x.id === updatedForm.id);
        formsCopy.splice(index, 1, updatedForm); //Reemplazo form.
        setVisitTracking(visit);
        setEvolutions(visit.evolutions);
        setForms(formsCopy);
        setSelectedForm(updatedForm);

        _closeModal();
        Success("protocolNotifications.visitTracking_FormSaved");
    }

    const _saveVisit = async (formVisitTracking, formikProps) => {
        try {
            if (formikProps.isSubmitting)
                return;

            formikProps.setSubmitting(true);
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            let res = await saveVisitTrackingExtraForm(protocolId, visitId, patientId, formVisitTracking);
            let form = res.data.form;
            const visit = res.data.visit;

            // Upload attachments if any. 
            const fieldsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "FileUploadField" && x.value.file);
            const componentsWithFiles = formVisitTracking.patientForm.fields.filter(x => x._t === "ArrayField")
                .map(array => {
                    return array.components.map(component => {
                        return component.fields.map(field => field._t === "FileUploadField" && field !== null && field).flat();
                    }).flat();
                }).flat().filter(x => x.value?.file); // filtro por los nuevos solamente. 
            const files = fieldsWithFiles.concat(componentsWithFiles);

            if (files && files.length > 0) {
                try {
                    const attachs = files.map(x => x.value);
                    postAPI(attachs, uploadVisitTrackingExtraAttachmentFormEndpoint(protocolId, visitId, form.id, patientId))
                        .then(() => {
                            getVisitTrackingForm(protocolId, visitId, form.id)
                                .then(resForm => {
                                    // Actualizo el form en FrontEnd.
                                    _updateData(visit, resForm?.data);
                                }).catch((error) => {
                                    console.log(error)
                                    window.location.reload(false)
                                })
                        }).catch((error) => { console.log(error) });
                }
                catch (error) {
                    console.log(error.response);
                }
            }
            else {
                _updateData(visit, form);
            }
        }
        catch (error) {
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
        finally {
            formikProps.setSubmitting(false);
        }
    }

    const _publishVisit = async (digitalSign, formikProps, type) => {

        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);
            const res = await publishVisitTrackingExtraForm(protocolId, visitId, patientId, type, digitalSign);
            setModal(null);
            setSelectedForm({ text: res.data.text });
            setVisitTracking(res.data);
            formikProps.setSubmitting(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            setModal(null);
            formikProps.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleSaveVisit = (formVisitTracking, formikProps) => {

        if (visitTracking.status !== visitStatusEnum.Started || formVisitTracking.status !== formStatus.Initial) {
            setModal(<CreateAuditModal
                onClose={() => setModal(null)}
                onSave={(reason, comments) => _saveVisit({ patientForm: formVisitTracking, audit: { reason: reason, comments: comments } }, formikProps)}
            />)
        }
        else {
            _saveVisit({ patientForm: formVisitTracking }, formikProps);
        }
    }

    const handleSubmitFormik = (_formVisitTracking, actions) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); actions.setSubmitting(false); }}
                onSubmit={(pin) => _publishVisit(pin, actions, "ExtraProtocol")}
            />);
        else
            _publishVisit({ password: "" }, actions, "ExtraProtocol")
    }

    const handleSubmitForm = (evt, formikBag) => {
        evt.preventDefault();

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            formikBag.submitForm();
        }
    }

    const handleAuditTrail = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                {t("auditTrail.field")}:
                <AuditTrail entity="VisitTrackingExtraForm" entityId={selectedForm.id} fields={selectedForm && selectedForm.fields} />

                {t("auditTrail.evolution")}:
                <AuditTrail entity="VisitTrackingExtra" entityId={visitTracking.id} />
            </AuditModal>
        )
    }

    const handleViewFile = async (attachName) => {
        try {
            await getBlobAPI(attachName, getVisitTrackingAttachmentFormEndpoint(protocolId, visitId, selectedForm.id, attachName));
        }
        catch (error) {
            console.error(error);
            HandleApiError(error);
        }
    }

    const handleSelectForm = (formId, isDirty) => {
        if (!isDirty || (isDirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            let selectedForms = forms.filter(x => { return x.id === formId });
            let formAux = selectedForms.length > 0 ? selectedForms[0] : {};
            formAux.text = selectedForm.text;

            setSelectedForm(formAux);
        }
    }

    const handleScreenFailure = (formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(<ScreenFailure
                onClose={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmScreenFailure(formikBag)}
            />)
        }
    }

    const confirmScreenFailure = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishScreenFailure(pin, formikBag)}
            />);
        else
            _publishScreenFailure({ password: "" }, formikBag)
    }

    const _publishScreenFailure = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await screenFailureVisitTrackingExtra(protocolId, visitId, patientId, digitalSign);
            setModal(null);
            setSelectedForm({ text: res.data.text });
            setVisitTracking(res.data);
            formikBag.setSubmitting(false);
            setIsLoading(false);
            Success("protocolNotifications.visitTracking_VisitPublished");
        }
        catch (error) {
            setModal(null);
            setIsLoading(false);
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleEarlyTermination = (type, formikBag) => {

        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(<ModalConfirm
                onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
                onConfirm={() => confirmEarlyTermination(type, formikBag)}
                title={t(`protocolNotifications.visitTracking_confirm${type}_title`)}
                description={t(`protocolNotifications.visitTracking_confirm${type}_description`)}
            />)
        }
    }

    const confirmEarlyTermination = (type, formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishVisit(pin, formikBag, type)}
            />);
        else
            _publishVisit({ password: "" }, formikBag, type)
    }

    const handleRemoveVisit = (formikBag) => {
        setModal(<ModalConfirm
            title={t("protocolNotifications.visitTracking_removeVisit_title")}
            description={t("protocolNotifications.visitTracking_removeVisit_description")}
            onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
            onConfirm={() => _confirmRemoveVisit()}
        />)
    }

    const _confirmRemoveVisit = async () => {
        try {
            setIsLoading(true);
            await removeVisitTrackingExtraAPI(protocolId, visitId, patientId);
            Success("protocolNotifications.visitTracking_removeVisitOk");
            history.push(`/admin/protocols/${protocolId}`);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleEndPatientInProtocol = (formikBag) => {
        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(
                <ModalConfirm
                    onConfirm={() => confirmEndPatientInProtocol(formikBag)}
                    onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
                    title={t("protocolNotifications.visitTracking_endPatientInProtocol_title")}
                    description={t("protocolNotifications.visitTracking_endPatientInProtocol_description")}
                />);
        }
    }
    const confirmEndPatientInProtocol = (formikBag) => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                isLoading={isLoading}
                hide={() => { setModal(null); formikBag.setSubmitting(false); }}
                onSubmit={(pin) => _publishEndPatientInProtocol(pin, formikBag)}
            />);
        else
            _publishEndPatientInProtocol({ password: "" }, formikBag)
    }
    const _publishEndPatientInProtocol = async (digitalSign, formikBag) => {
        try {
            setIsLoading(true);
            const res = await endPatientInProtocolVisitTrackingExtraAPI(protocolId, visitId, patientId, digitalSign);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_FormSaved");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }

    const handleRollbackVisitStatus = (formikBag) => {
        if (!formikBag.dirty || (formikBag.dirty && window.confirm(t("protocolNotifications.visitTracking_unsavedChanges")))) {
            setModal(
                <ModalConfirm
                    onConfirm={() => confirmRollbackVisitStatus(formikBag)}
                    onCancel={() => { setModal(null); formikBag.setSubmitting(false); }}
                    title={t("protocolNotifications.visitTracking_rollbackVisitStatus_title")}
                    description={t("protocolNotifications.visitTracking_rollbackVisitStatus_description")}
                />);
        }
    }
    const confirmRollbackVisitStatus = (formikBag) => {
        setModal(<CreateAuditModal
            onClose={_closeModal}
            onSave={(reason, comments) => _publishRollbackVisitStatus({ audit: { reason: reason, comments: comments } }, formikBag)}
        />)
    }
    const _publishRollbackVisitStatus = async (audit, formikBag) => {
        try {
            setIsLoading(true);
            const res = await rollbackVisitStatusExtraAPI(protocolId, visitId, patientId, audit);
            _closeModal();
            setIsLoading(false);
            setVisitTracking(res.data);
            Success("protocolNotifications.visitTracking_rollbackDone");
            formikBag.setSubmitting(false);
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            formikBag.setSubmitting(false);
            console.log(error);
            HandleApiError(error);
        }
    }


    const _export = async (pin) => {
        try {
            setModal(<ModalSpinner isShowing={true} hide={null} />);

            const response = await exportVisitTrackingAPI(protocolId, visitTracking?.id, patientId, pin?.password);
            //const blob = new Blob([response.data], { type: "text/html" });
            const blob = new Blob([response.data]);
            const url = window.URL.createObjectURL(blob);
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';

            var fileName = "PatientVisit.zip";
            var disposition = response.headers['content-disposition'];
            if (disposition && disposition.indexOf('attachment') !== -1) {
                var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
                var matches = filenameRegex.exec(disposition);
                if (matches != null && matches[1]) fileName = matches[1].replace(/['"]/g, '');
            }
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
            window.URL.revokeObjectURL(url);
            _closeModal();
        }
        catch (error) {
            setIsLoading(false);
            _closeModal();
            console.log(error);
            HandleApiError(error);
        }

    }
    const handleExport = async () => {
        if (settingsStore.settings.digitalSignEnabled)
            setModal(<ModalDigitalSign
                isShowing={true}
                hide={() => { setModal(null); }}
                onSubmit={(pin) => _export(pin)}
            />);

        else
            _export("")
    }

    if (!visitTracking || isLoading || isAuditLoading)
        return <Spinner />;

    const isDisabled = !isAuthorized(["VisitTracking.Add"]) || context.isDisabled;

    return (
        <>
            {modal}
            <DocumentViewer
                file={fileData?.blob}
                fileName={fileData?.fileName}
                inModal={true}
                isLoading={fileData?.isLoading}
            />
            <Formik
                initialValues={selectedForm}
                validationSchema={getValidationSchema}
                onSubmit={handleSubmitFormik}
                enableReinitialize={true}
                validateOnBlur={false}
            >
                {props => (
                    <form onSubmit={(evt) => handleSubmitForm(evt, props)}>
                        <Row>
                            <Col md={4} lg={3}>
                                <Row>
                                    <Col md={12}>
                                        <FormMenu
                                            forms={forms}
                                            onClick={(formId) => handleSelectForm(formId, props.dirty)}
                                            selectedFormId={selectedForm?.id}
                                            formsWithErrors={[]}
                                        />
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={12}>
                                        <FormActions
                                            protocolId={protocolId}
                                            visit={visitTracking}
                                            forms={forms}
                                            patientInProtocolStatus={patientInfo?.patientInProtocolStatus}
                                            onBack={(evt) => { evt.preventDefault(); history.goBack(); }}
                                            onSave={() => handleSaveVisit(props.values, props)}
                                            onAuditTrail={handleAuditTrail}
                                            onScreenFailure={() => handleScreenFailure(props)}
                                            onWithoutDrugs={() => handleEarlyTermination("WithoutDrugs", props)}
                                            onDiscontinuation={() => handleEarlyTermination("Discontinuation", props)}
                                            onEndPatientInProtocol={() => handleEndPatientInProtocol(props)}
                                            onRollbackVisitStatus={() => handleRollbackVisitStatus(props)}
                                            onBackToMR={() => history.push(`/admin/medicalRecords/${visitTracking.patientId}`)}
                                            onRemove={() => handleRemoveVisit(props)}
                                            onExport={handleExport}
                                            isSubmitting={props.isSubmitting}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={4} lg={5}>
                                {visitTracking && <VisitStatus status={visitTracking?.status} date={visitTracking?.created} />}
                                <DynamicForm
                                    {...props}
                                    patientId={visitTracking.patientId}
                                    patientNumber={patientInfo?.patientNumber}
                                    visitId={visitId}
                                    protocolId={protocolId}
                                    onDownload={handleViewFile}
                                    isDisabled={isDisabled}
                                    getLastChange={getLastVisitTrackingChange}
                                />
                                <WithAuth protocolId={protocolId} requiredPermission={["InternalNotes.List"]}>
                                    {
                                        <DocumentNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm?.id}
                                            visitId={visitId}
                                            patientId={patientInfo?.patientNumber}
                                            type="VisitTrackingExtra"
                                        />
                                    }
                                </WithAuth>
                            </Col>
                            <Col md={4} lg={4} className="sticky">
                                <button type="button"
                                    className="btn btn-primary btn-fill btn-full"
                                    onClick={() => { setShowHistory(!showHistory) }}
                                    style={{ marginBottom: '22px', textAlign: 'center' }}>
                                    <MedicalRecord /> {t("protocols.visitTracking.viewMR")}
                                </button>
                                <EvolutionsContainer
                                    visitType="VisitTrackingExtra"
                                    medicalRecordNumber={patientInfo.medicalRecordNumber}
                                    protocolId={protocolId}
                                    visitId={visitId}
                                    patientId={patientId}
                                    visitTrackingStatus={visitTracking.status}
                                    data={evolutions}
                                    {...props}
                                    audit={getLastVisitTrackingChange("VisitText")} //Text
                                    isDisabled={isDisabled}
                                    title={getVisitTypeTitle(visitTracking.type)}
                                    subtitle={patientInfo?.patientNumber}
                                />
                                {
                                    !settingsStore.settings.digitalSignEnabled && (visitTracking?.status === visitStatusEnum.Completed || visitTracking?.status === visitStatusEnum.Skipped || visitTracking?.status === visitStatusEnum.Failed) &&
                                    <SignWithOutInfo signedBy={visitTracking.createdById} created={visitTracking.created} locale={true} />
                                }
                                {
                                    settingsStore.settings.digitalSignEnabled &&
                                    <DigitalSign signedBy={visitTracking.digitalSign?.signedBy} signStatus={signStatus} signedDate={visitTracking.digitalSign?.signedDate} />
                                }
                                <WithAuth protocolId={protocolId} requiredPermission={["ExternalNotes.List"]}>
                                    {
                                        <MonitorNotesContainer
                                            protocolId={protocolId}
                                            documentId={selectedForm?.id}
                                            visitId={visitId}
                                            patientId={patientInfo?.patientNumber}
                                            type="VisitTrackingExtra"
                                        />
                                    }
                                </WithAuth>
                            </Col>
                            <Slider isOpen={showHistory}>
                                <Grid fluid>
                                    <button type="button"
                                        className="btn btn-primary btn-fill btn-full"
                                        onClick={() => { setShowHistory(!showHistory) }}
                                        style={{ margin: '22px 0', textAlign: 'center' }}>
                                        <MedicalRecord /> {t("protocols.visitTracking.hideMR")}
                                    </button>
                                    <EntryItemsListTabs medicalRecordNumber={visitTracking.patientId} inProtocol />
                                </Grid>
                            </Slider>
                        </Row>
                    </form>
                )}
            </Formik>
        </>
    );
}