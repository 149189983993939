import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Col, ControlLabel, Grid, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import overlayFactory from 'react-bootstrap-table2-overlay';
import { useTranslation } from 'react-i18next';
import { CustomSelect } from '../../../components/CustomControls/CustomSelect';
import { ProtocolPicker } from '../../../components/CustomControls/ProtocolPicker';
import { useCopyForms, useForms } from './useForms';
import { useVisits } from './useVisits';
import { Copy } from '../../../components/Icons/Icons';
import { Spinner } from '../../../components/Utils/Loaders';

export const CopyFormsContainer = ({ protocolId, visitId, crossVersionId, onCancel, onSubmitted }) => {
    const { t } = useTranslation();
    const [getVisits, data, setVisits, isVisitLoading] = useVisits();
    const [getForms, forms, setForms, isFormsLoading] = useForms();
    const [copyForms, isLoading] = useCopyForms(protocolId, visitId);
    const [selectedProtocolId, setSelectedProtocolId] = useState(null);
    const [selectedVisitId, setSelectedVisitId] = useState(null);
    const [selectedForms, setSelectedForms] = useState([]);

    const handleProtocolSelected = (_name, selected) => {
        setSelectedVisitId(null);
        if (selected && selected.length > 0) {
            getVisits(selected[0].id);
            setSelectedProtocolId(selected[0].id)
        }
        else {
            setSelectedProtocolId(null);
            setVisits([]);
            setForms([]);
        }
    }

    const handleVisitSelected = (_name, selected) => {
        if (selected) {
            getForms(selectedProtocolId, selected.crossVersionId);
            setSelectedVisitId(selected.crossVersionId);
        }
        else {
            setSelectedVisitId(null);
            setVisits([]);
            setForms([]);
        }
    }

    const handleCopyForms = async () => {
        if (selectedForms && selectedForms.length > 0) {
            const res = await copyForms(selectedProtocolId, selectedVisitId, selectedForms);
            onSubmitted()
        }
    }

    const columns = [
        { dataField: 'title', text: t("protocols.visit.formTitle") },
    ];

    const selectRow = {
        mode: 'checkbox',
        onSelect: (row, isSelect) => {
            if (isSelect)
                setSelectedForms(selectedForms.concat(row.id));
            else
                setSelectedForms(selectedForms.filter((id) => id !== row.id));
        },
        onSelectAll: (isSelect, rows) => {
            if (isSelect) {
                setSelectedForms(rows.map(r => r.id));
            }
            else {
                setSelectedForms([]);
            }
        },
    };

    if (isLoading)
        return <Spinner />;

    return (
        <>
            <Grid fluid>
                <Row>
                    <Col sm={12}>
                        <ControlLabel>{t("protocols.visit.copyVisits_selectProtocol")}</ControlLabel>
                        <ProtocolPicker
                            id="copy-forms"
                            name="protocols"
                            multiple={false}
                            placeholder={t("filters.protocol")}
                            onChange={handleProtocolSelected} />
                    </Col>
                    {
                        data && data.length > 0 &&
                        <Col sm={12}>
                            <ControlLabel>{t("protocols.visit.copyForms_selectVisit")}</ControlLabel>
                            <CustomSelect
                                name="visits"
                                isClearable={true}
                                isLoading={isVisitLoading}
                                placeholder={t('protocols.visit.copyForms_selectVisit')}
                                options={data}
                                getOptionLabel={(option) => option.title}
                                getOptionValue={(option) => option.id}
                                onChange={handleVisitSelected}
                            />
                        </Col>
                    }
                </Row>
            </Grid>
            {
                forms && forms.length > 0 &&
                <Grid fluid style={{ marginTop: '10px' }}>
                    <Row>
                        <Col sm={12}>
                            <ControlLabel>{t("protocols.visit.copyForms_forms")}</ControlLabel>
                            <BootstrapTable
                                classes="table-spaced table-spaced-smallest table-spaced-small"
                                wrapperClasses="table-responsive"
                                bordered={false}
                                hover
                                remote
                                keyField='id'
                                data={forms}
                                columns={columns}
                                noDataIndication={() => <>{t("commons.noData")}</>}
                                loading={isFormsLoading}
                                overlay={overlayFactory({ spinner: true })}
                                selectRow={selectRow}
                            />
                        </Col>
                    </Row>
                </Grid>
            }
            <Grid fluid style={{ marginTop: '10px' }}>
                <Row>
                    <Col sm={12}>
                        <button onClick={handleCopyForms} disabled={!selectedForms || selectedForms.length === 0} className="btn btn-primary btn-right"><Copy /> {t('protocols.visit.copyVisits_confirm')}</button>
                        <button type="button" className="btn btn-default btn-right" onClick={onCancel} style={{ marginRight: '10px' }}>{t("buttons.cancel")}</button>
                    </Col>
                </Row>
            </Grid>
        </>
    );
}

CopyFormsContainer.propTypes = {
    protocolId: PropTypes.number.isRequired,
    crossVersionId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    visitId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
};