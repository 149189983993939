import React from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import overlayFactory from 'react-bootstrap-table2-overlay'
import { useTranslation } from 'react-i18next'
import { dateFormatter, dateTimeFormatter } from '../Utils/Formatter'

export const AuditProtocolUserRole = (props) => {
    const { data, isLoading } = props;
    const { t } = useTranslation();

    const columns = [
        { dataField: 'actionDateTime', text: t('protocols.auditUserRole.dateTime'), formatter: dateTimeFormatter },
        { dataField: 'actionByUser.fullName', text: t('protocols.auditUserRole.actionBy') },
        { dataField: 'role.name', text: t('protocols.auditUserRole.role') },
        { dataField: 'start', text: t('protocols.auditUserRole.start'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YYYY") } },
        { dataField: 'end', text: t('protocols.auditUserRole.end'), formatter: function (cell) { return dateFormatter(cell, "DD/MM/YYYY") } }
    ];

    return (
        <BootstrapTable
            bordered={false}
            hover
            remote
            keyField='id'
            classes="table-spaced table-spaced-small"
            wrapperClasses="table-responsive"

            data={data}
            columns={columns}
            noDataIndication={() => <>{t("commons.noData")}</>}
            loading={isLoading}  //only loading is true, react-bootstrap-table will render overlay
            overlay={overlayFactory({ spinner: true })}
        />
    );
}

AuditProtocolUserRole.propTypes = {
};