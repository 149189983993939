import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from "react-router-dom";
import { removeRole } from "../../../api/security";
import { AuditModal } from "../../../components/AuditTrail/AuditModal";
import { Roles } from "../../../components/Authorization/Roles";
import { WithAuth } from "../../../components/Authorization/WithAuth";
import Card from "../../../components/Cards/Card";
import { Add, Audit } from "../../../components/Icons/Icons";
import { HandleApiError } from "../../../components/Notifications/APIErrorHandler";
import { Success } from "../../../components/Notifications/Notifications";
import AdminContext from "../../Settings/AdminContext/AdminContext";
import { useRoles } from "./useRoles";
import { AuditRolePermissionsContainer } from "../Audit/AuditRolePermissions";

export const RolesContainer = () => {
    let history = useHistory();
    const { t } = useTranslation();
    const [modal, setModal] = useState(null);
    const [query, setQuery] = useState({
        filters: {}
    });

    const [roles, isLoading, reloadRoles] = useRoles(query);
    const context = useContext(AdminContext);

    useEffect(() => {
        var newQuery = JSON.parse(JSON.stringify(query));
        newQuery.filters["TenantId"] = context?.selectedTenant != null ? context.selectedTenant.tenantId : null;
        setQuery(newQuery);

    }, [context]);

    const handleEditRole = (roleId) => {
        history.push(`/admin/settings/roles/${roleId}`);
    }

    const handleRemoveRole = (roleId) => {
        if (roleId) {
            removeRole(roleId)
                .then(res => {
                    reloadRoles(true);
                    Success("security.roles.removed");
                })
                .catch(error => { HandleApiError(error); })
        }
    }

    //#region Audit
    const handleAuditUserRole = () => {
        setModal(
            <AuditModal
                onClose={() => { setModal(null); }}
                title={t("auditTrail.audit")}
            >
                <AuditRolePermissionsContainer />
            </AuditModal>
        )
    }
    //#endregion

    return (
        <>
            {modal}
            <Card
                tabs
                header={
                    <Row>
                        <Col md={2}>
                            <WithAuth requiredPermission={["Security.Create"]}>
                                <NavLink to={`/admin/settings/roles/create`} className="btn btn-primary" activeClassName="active">
                                    <Add /> {t("security.roles.add")}
                                </NavLink>
                            </WithAuth>
                        </Col>
                        <Col md={6} mdOffset={2}>
                        </Col>
                        <Col md={2}>
                            <button className="btn btn-default btn-right" onClick={handleAuditUserRole} style={{ marginRight: '10px' }}>
                                <Audit /> {t("auditTrail.auditPermissions")}
                            </button>
                        </Col>
                    </Row>
                }
                content={
                    <Roles
                        roles={roles}
                        onEdit={handleEditRole}
                        onRemove={handleRemoveRole}
                        isLoading={isLoading}
                    />
                }
            />
        </>
    );
}